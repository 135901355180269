import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const b2bpartner = () => {

const partnermail = 'partner@budgettrips.de'

return (
  <Layout>
    <Seo title="B2B & Partner" />
    <div className="container text-start my-5">
      <h1>Kontakt für B2B und Partner</h1>

      <p>Sie arbeiten bereits mit uns zusammen oder sind interessiert an einer Kooperation?</p>

      <p>Schreiben Sie uns direkt an {partnermail}.</p>
           
    </div>
  </Layout>
)}

export default b2bpartner
